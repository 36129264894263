.titlebar {
  display: table;
  width: 100%;
}
.titlebar-text,
.titlebar-login,
.titlebar-logout {
  display: table-cell;
}
.titlebar-login, .titlebar-logout {
  width: auto;
}

.login-logo {
  width: 45px;
}

.camera {  
  width: 100%;
  min-height: 10vh;
}

.subtitle {
  display: none;
}

.media-left, .media-body {
  display: none;
}

.input-group-top-space {
  padding-top: 5px;
}

@media only screen and (min-width: 768px) {
  .media-left, .media-body {
    display: table-cell;
  } 
  .subtitle {
    display: inline;
  }
  .titlebar-logout {
    width: 200px;
  }
  .titlebar-login {
    width: 80px;
  }
}
